import { Component, computed, effect, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';
import { AuthService, BreadcrumbsService } from '@suvo-bi-core';
import { BehaviorSubject, Subject, lastValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IMeasurement } from '../../../measurement/measurement.interface';
import { MeasurementService } from '../../../measurement/measurement.service';
import { IMicroscope } from '../../../microscopes/interface/microscope.interface';
import { MicroscopeService } from '../../../microscopes/service/microscope.service';
import { ISample } from '../../../samples/interface/sample.interface';
import { SamplesService } from '../../../samples/service/samples.service';
import { ITaxon, TCountingMethod } from '../../../taxon/taxon.interface';
import { TaxonService } from '../../../taxon/taxon.service';
import { IRecordingEvent } from '../../../tows/interface/recording-event.interface';
import { UsersService } from '../../../users/service/users.service';
import { SampleFinaliseDialogComponent } from '../../components/sample-finalise-dialog/sample-finalise-dialog.component';
import { EditComponent } from 'apps/mba-cpr-survey-portal/src/app/shared/components/edit/edit.component';
import { IUser } from '@suvo-bi-users';
import { User } from 'firebase/auth';
import { ICprUser } from '../../../users/interface/user.interface';

interface ProcessedMeasurement {
  measurement: IMeasurement;
  taxon: ITaxon;
  other: boolean;
  count: number | string;
}

@Component({
  selector: 'app-sample-data-entry-screen',
  templateUrl: './sample-data-entry-screen.component.html',
  styleUrl: './sample-data-entry-screen.component.scss',
})
export class SampleDataEntryScreenComponent implements OnInit, OnDestroy {
  @ViewChild('editComponent') editComponent: EditComponent;
  private readonly unsubscribe$ = new Subject<void>();

  currentUser: ICprUser;
  dataEntryEnabled = false;

  private readonly modelState = new BehaviorSubject<IMeasurement[]>([]);
  measurements: IMeasurement[];
  processedMeasurements: ProcessedMeasurement[];

  measurementsAreLoading: boolean;

  tow: IRecordingEvent<'tow'>;
  sample: ISample;
  microscopes: IMicroscope[];

  detailsFormGroup: FormGroup;
  measurementFormGroup = new FormGroup({
    taxon: new FormControl<ITaxon | string>(undefined, {
      validators: (control) => (typeof control.value === 'object' ? null : { invalidTaxon: true }),
    }),
    quantity: new FormControl<string>(''),
  });

  taxonSearch: ITaxon[];
  private readonly taxaSearchDebounce = 200;
  private taxaSearchTimeout: number;

  countingMethod: ITaxon['countingMethod'] = 'PHYTO';

  constructor(
    private readonly route: ActivatedRoute,
    private readonly dialog: MatDialog,
    private readonly breadcrumbService: BreadcrumbsService,
    private readonly measurementService: MeasurementService,
    private readonly microscopeService: MicroscopeService,
    private readonly sampleService: SamplesService,
    private readonly taxonService: TaxonService,
    private readonly userService: UsersService,
    private readonly authService: AuthService,
  ) {}

  async ngOnInit() {
    this.currentUser = await this.userService.getMe();

    this.route.data.pipe(takeUntil(this.unsubscribe$)).subscribe(({ data }) => {
      this.tow = data;
    });

    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe(async ({ sampleID }) => {
      this.measurementsAreLoading = true;
      this.measurementFormGroup.disable();

      this.sample = await this.sampleService.getOne('', sampleID);
      this.breadcrumbService.setDynamicNameSwap('sampleNo', this.sample.sampleNo.toString());

      this.detailsFormGroup = new FormGroup({
        microscopeId: new FormControl(this.sample.microscopeId ?? '', { nonNullable: true }),
        comment: new FormControl(this.sample.comment ?? '', { nonNullable: true }),
      });

      await this.pull();

      if (
        !this.sample.finalisedAt &&
        this.sample.userId &&
        this.currentUser._id == this.sample.userId
      ) {
        this.dataEntryEnabled = true;
        this.measurementFormGroup.enable();
      }

      await this.taxonService.getFlat();

      this.measurementsAreLoading = false;
    });

    this.modelState.pipe(takeUntil(this.unsubscribe$)).subscribe(async (measurements) => {
      this.measurements = measurements;

      let measurementsWithTaxons = [];

      for (let measurement of measurements) {
        measurementsWithTaxons.push({
          measurement,
          taxon: await this.taxonService.getById(measurement.taxonId),
        });
      }

      this.processedMeasurements = measurements
        .map((_, i) => {
          let { measurement, taxon } = measurementsWithTaxons[i];
          const other = !!taxon.taxonChildren?.length;
          if (other && typeof measurement.analysedCount === 'number') {
            const childSum = measurementsWithTaxons.reduce((sum, next) => {
              return typeof next.measurement.analysedCount === 'number' &&
                next.taxon.parentTaxonIds?.includes(taxon.cprTaxonId)
                ? sum + next.measurement.analysedCount
                : sum;
            }, 0);
            measurement = { ...measurement, analysedCount: measurement.analysedCount - childSum };
          }
          return {
            measurement,
            taxon,
            other,
            count: other ? '?' : measurement.analysedCount,
          };
        })
        .filter(
          ({ other, measurement }) =>
            !other ||
            measurement.analysedCount === '+' ||
            (typeof measurement.analysedCount === 'number' && measurement.analysedCount > 0),
        )
        .sort((a, b) => a.taxon.taxonName.localeCompare(b.taxon.taxonName));
    });

    this.microscopes = await this.microscopeService.allMicroscopes;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async pull() {
    this.modelState.next(await this.sampleService.getMeasurements(this.sample._id));
  }

  onSaveDetails(event: ISample) {
    this.sampleService.updateSampleDetails(this.sample._id, event);
    this.editComponent.markAsUpdated();
  }

  async addMeasurement() {
    const { _id: sampleId } = this.sample;
    const { _id: taxonId } = this.measurementFormGroup.value.taxon as ITaxon;
    const { quantity } = this.measurementFormGroup.value;
    const analysedCount = quantity === '+' ? ('+' as const) : parseInt(quantity);
    const measurement = { analysedCount, sampleId, taxonId };
    await this.measurementService.setMeasurement(measurement);
    this.measurementFormGroup.reset();
    this.measurementFormGroup.markAsPristine();
    Object.values(this.measurementFormGroup.controls).forEach((control) => control.setErrors(null));
    this.taxonSearch = undefined;
    await this.pull();
  }

  onInput() {
    this.taxonSearch = undefined;
    if (this.taxaSearchTimeout) {
      clearTimeout(this.taxaSearchTimeout);
    }
    this.taxaSearchTimeout = window.setTimeout(async () => {
      const searchTerm = this.measurementFormGroup.value.taxon as string;
      this.taxonSearch = await this.taxonService.search(searchTerm, this.countingMethod);
    }, this.taxaSearchDebounce);
  }

  getTaxonDisplayValue(value: ITaxon) {
    if (value?.taxonChildren?.length) {
      const bracketContent = value.countingMethod === 'PHYTO' ? 'total' : 'extra';
      return `${value.taxonName} (${bracketContent})`;
    } else {
      return value?.taxonName;
    }
  }

  async getAnalystName(userId: string) {
    return (await this.userService.getByUserId(userId))?.displayName;
  }

  finaliseSample() {
    const dialogref = this.dialog.open(SampleFinaliseDialogComponent, {
      data: {
        sampleId: this.sample._id,
        onConfirm: () => {
          this.sample.finalisedAt = new Date(Date.now());
        },
      },
    });

    lastValueFrom(dialogref.backdropClick()).then(() => {
      this.cancelFinalising();
    });
  }

  async cancelFinalising() {
    await this.sampleService.unfinalise(this.sample._id);
    this.sample = await this.sampleService.getOne('', this.sample._id);
  }

  onCountingMethodChange() {
    this.measurementFormGroup.reset();
  }

  hasMeasurementsForCountingMethod(countingMethod: TCountingMethod) {
    return this.processedMeasurements?.find((m) => m.taxon?.countingMethod == countingMethod);
  }
}
