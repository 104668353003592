import { CSD, ITowLeg, ITowLogEvent } from '../interfaces/tow-log-event.interface';

/*
 * Much of the following code originates from web console, adapted for JavaScript from C#.NET.
 */

export function calculateMillisecondDifference(
  start: Date | string | number,
  end: Date | string | number,
) {
  return new Date(end).getTime() - new Date(start).getTime();
}

export function calculateHourDifference(
  start: Date | string | number,
  end: Date | string | number,
) {
  return calculateMillisecondDifference(start, end) / 36e5;
}

export function calculateCourse(
  start: ITowLogEvent<unknown, Date | string>,
  end: ITowLogEvent<unknown, Date | string>,
  distance = calculateVincentyDistance(start, end),
) {
  let c: number;
  distance = (distance * Math.PI * 2) / (360 * 60);
  const lat1 = degreesToRadians(roundWithPrecision(start.eventLatitude, 3));
  const lat2 = degreesToRadians(roundWithPrecision(end.eventLatitude, 3));
  const long1 = degreesToRadians(roundWithPrecision(start.eventLongitude, 3));
  const long2 = degreesToRadians(roundWithPrecision(end.eventLongitude, 3));
  if (Math.sin(long2 - long1) == 0 && lat1 - lat2 > 0) {
    c = Math.PI;
  } else if (Math.sin(long2 - long1) == 0 && lat1 - lat2 <= 0) {
    c = 0;
  } else {
    const top = Math.sin(lat2) - Math.sin(lat1) * Math.cos(distance);
    const bottom = Math.sin(distance) * Math.cos(lat1);
    const result = Math.acos(top / bottom);
    if (Math.sin(long2 - long1) > 0) {
      c = result;
    } else {
      c = 2 * Math.PI - result;
    }
  }

  return c;
}

export function calculateCourseSpeedDistance(
  start: ITowLogEvent<unknown, Date | string>,
  end: ITowLogEvent<unknown, Date | string>,
) {
  if (!start || end.eventCode === 'MIDSHOOT') {
    return {
      course: null,
      speed: 0,
      distance: 0,
    };
  }
  const distance = calculateVincentyDistance(start, end);
  return {
    course: radiansToDegrees(calculateCourse(start, end, distance)),
    distance,
    speed: distance / calculateHourDifference(start.eventDate, end.eventDate),
  };
}

export function calculateVincentyDistance(
  start: ITowLogEvent<unknown, Date | string>,
  end: ITowLogEvent<unknown, Date | string>,
) {
  const equatorRadius = 6378137;
  const minorAxis = 6356752.3142;
  const reciprocalFlattening = 1 / 298.257223563;
  const dLong = degreesToRadians(start.eventLongitude - end.eventLongitude);
  const u1 = Math.atan(
    (1 - reciprocalFlattening) * Math.tan(degreesToRadians(start.eventLatitude)),
  );
  const u2 = Math.atan((1 - reciprocalFlattening) * Math.tan(degreesToRadians(end.eventLatitude)));
  const sinU1 = Math.sin(u1);
  const sinU2 = Math.sin(u2);
  const cosU1 = Math.cos(u1);
  const cosU2 = Math.cos(u2);
  let lambda = dLong;
  let lambdaPi = 2 * Math.PI;
  let iterLimit = 20;
  const errorValue = 1e-12;
  let sinSigma = 0;
  let cosSigma = 0;
  let sigma = 0;
  let cosSqAlpha = 0;
  let cos2SigmaM = 0;

  while (Math.abs(lambda - lambdaPi) > errorValue && iterLimit - 1 > 0) {
    iterLimit--;

    const sinLambda = Math.sin(lambda);
    const cosLambda = Math.cos(lambda);
    sinSigma = Math.sqrt(
      cosU2 * sinLambda * (cosU2 * sinLambda) +
        (cosU1 * sinU2 - sinU1 * cosU2 * cosLambda) * (cosU1 * sinU2 - sinU1 * cosU2 * cosLambda),
    );

    if (sinSigma === 0) return 0;

    cosSigma = sinU1 * sinU2 + cosU1 * cosU2 * cosLambda;
    sigma = Math.atan2(sinSigma, cosSigma);

    const sinAlpha = (cosU1 * cosU2 * sinLambda) / sinSigma;
    cosSqAlpha = 1 - sinAlpha * sinAlpha;
    cos2SigmaM = cosSigma - (2 * sinU1 * sinU2) / cosSqAlpha;

    if (isNaN(cos2SigmaM)) cos2SigmaM = 0;

    const c =
      (reciprocalFlattening / 16) * cosSqAlpha * (4 + reciprocalFlattening * (4 - 3 * cosSqAlpha));
    lambdaPi = lambda;
    lambda =
      dLong +
      (1 - c) *
        reciprocalFlattening *
        sinAlpha *
        (sigma + c * sinSigma * (cos2SigmaM + c * cosSigma * (-1 + 2 * cos2SigmaM * cos2SigmaM)));
  }

  if (iterLimit === 0) return NaN;

  const uSq =
    (cosSqAlpha * (equatorRadius * equatorRadius - minorAxis * minorAxis)) /
    (minorAxis * minorAxis);
  const a = 1 + (uSq / 16384) * (4096 + uSq * (-768 + uSq * (320 - 175 * uSq)));
  const b = (uSq / 1024) * (256 + uSq * (-128 + uSq * (74 - 47 * uSq)));
  const deltaSigma =
    b *
    sinSigma *
    (cos2SigmaM +
      (b / 4) *
        (cosSigma * (-1 + 2 * cos2SigmaM * cos2SigmaM) -
          (b / 6) *
            cos2SigmaM *
            (-3 + 4 * sinSigma * sinSigma) *
            (-3 + 4 * cos2SigmaM * cos2SigmaM)));

  let s = minorAxis * a * (sigma - deltaSigma);

  // // Round to 1mm precision
  s = roundWithPrecision(s, 3);

  // // Meters to nautical miles
  s = s * 0.000539956803;

  return s;
}

export function degreesToRadians(degrees: number) {
  return (degrees * Math.PI) / 180;
}

export function radiansToDegrees(radians: number) {
  return (radians * 180) / Math.PI;
}

export function roundWithPrecision(n: number, precision: number) {
  const multiple = 10 ** precision;
  return parseFloat((Math.round(n * multiple) / multiple).toFixed(precision));
}

export function getSortedTowLog<T extends ITowLogEvent<unknown, Date | string>>(towLog: T[]) {
  return [...towLog].sort(
    (a, b) => new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime(),
  );
}

export function getTowLegs<T extends ITowLogEvent<unknown, Date | string>>(towLog: T[]) {
  const legs: ITowLeg<T>[] = [];

  if (towLog.length < 2) {
    return legs;
  }

  const sorted = getSortedTowLog(towLog);

  for (let i = 1; i < sorted.length; i++) {
    const start = sorted[i - 1];
    const end = sorted[i];
    legs.push({
      start,
      end,
      ...calculateCourseSpeedDistance(start, end),
    });
  }

  return legs;
}

export function getFlattenedTowLegs<T extends ITowLogEvent<unknown, Date | string>>(towLog: T[]) {
  const legs: Array<T & CSD> = [];
  const sorted = getSortedTowLog(towLog);

  for (let i = 0; i < sorted.length; i++) {
    legs.push({
      ...sorted[i],
      ...(i == 0
        ? { course: null, speed: 0, distance: 0 }
        : calculateCourseSpeedDistance(sorted[i - 1], sorted[i])),
    });
  }

  return legs;
}
