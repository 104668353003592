import { Component, Input, OnChanges } from '@angular/core';
import { ICprUser } from '../../interface/user.interface';
import { UsersService } from '../../service/users.service';

@Component({
  selector: 'app-user-avatar',
  template: `
    @if (name) {
      <div style="display: flex; align-items: center; gap: 6px;">
        <div
          class="avatar-outline"
          [style.background]="bgColour"
          [matTooltip]="showLabel ? null : name"
        >
          <span>{{ user?.legacyAnalystId ?? initials }}</span>
        </div>
        @if (showLabel) {
          <span>{{ name }}</span>
        }
      </div>
    }
  `,
  styles: [
    `
      .avatar-outline {
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        width: 32px;
        min-width: 32px;
        user-select: none;
      }
    `,
  ],
})
export class UserAvatarComponent implements OnChanges {
  @Input() firebaseUserId: string;
  @Input() userId: string;
  @Input() showLabel = true;

  user: ICprUser;

  bgColour: string;
  initials: string;
  name: string;

  constructor(private readonly users: UsersService) {}

  async ngOnChanges() {
    if (this.firebaseUserId) {
      this.user = await this.users.getByFirebaseId(this.firebaseUserId);
    } else if (this.userId) {
      this.user = await this.users.getByUserId(this.userId);
    }

    this.name = this.user?.displayName ?? this.user?.name ?? undefined;

    this.getInitials();

    // Prefer deriving from the Firebase UID, which is more stable
    this.deriveColour();
  }

  private getInitials() {
    if (!this.name) {
      return '';
    }

    this.initials = this.name
      .split(' ')
      .slice(0, 2)
      .map((name) => name.charAt(0).toUpperCase())
      .join('');
  }

  private deriveColour() {
    if (!this.user) {
      this.bgColour = 'transparent';
      return;
    }

    const input = this.user.firebaseUserId ?? this.user._id;

    if (input === undefined || input === null) {
      return `hsl(0, 30%, 80%)`;
    }

    let sum = 0;

    if (typeof input === 'string') {
      for (let i = 0; i < input.length; i++) {
        btoa;
        sum += input.charCodeAt(i);
      }
    }

    this.bgColour = `hsl(${(sum * 100) % 360}, 30%, 80%)`;
  }
}
