import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { CheckBlocksTileComponent } from '../../../check-block/components/tile/check-blocks-tile.component';
import { ICheckBlock } from '../../../check-block/interface/check-block.interface';
import { SamplesService } from '../../service/samples.service';
import { CommonModule } from '@angular/common';
import { ICprUser } from '../../../users/interface/user.interface';
import { UsersService } from '../../../users/service/users.service';
import { PermissionsService } from '../../../permissions/permissions.service';

@Component({
  standalone: true,
  imports: [CheckBlocksTileComponent, CommonModule],
  providers: [SamplesService],
  selector: 'app-data-entry-check-blocks',
  template: `
    <app-check-blocks-tile
      *ngIf="!loading"
      [checkBlocks]="checkBlocks"
      tag="sample-checkblocks"
      [title]="isSeniorAnalyst ? 'Checkblocks' : 'Your active checkblocks'"
      [canShowResolved]="true"
    >
      <i whenNone>No check blocks to display.</i>
    </app-check-blocks-tile>
  `,
})
export class DataEntryCheckBlocksComponent {
  private readonly unsubscribe$ = new Subject<void>();

  loading = true;
  isSeniorAnalyst;

  checkBlocks: ICheckBlock[];

  constructor(
    route: ActivatedRoute,
    sampleService: SamplesService,
    private permissionsService: PermissionsService,
  ) {
    route.params.pipe(takeUntil(this.unsubscribe$)).subscribe(async ({ sampleID }) => {
      this.checkBlocks = await sampleService.getCheckBlocks(sampleID);
    });
  }

  async ngOnInit() {
    this.isSeniorAnalyst = this.permissionsService.hasPermission('checkblocks', 'create');

    this.loading = false;
  }
}
